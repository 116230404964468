<template>
	<Modal
		title="查看财务记录"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="viewData" ref="form" label-position="top">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="时间" prop="payTime">
						{{ viewData.payTime || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="费用名称" prop="name">
						{{ viewData.nickname || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="费用金额（元）" prop="amount">
						{{ viewData.amount || "0" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="支付类型" prop="payType">
						{{ String(viewData.payType) === "1" ? "收入" : "支出" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="状态" prop="status">
						{{ String(viewData.isDel) === "1" ? "已作废" : "正常" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注" prop="remark">
						{{ viewData.remark || "--" }}
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
export default {
	name: "ViewDetail",
	props: ["viewData", "visible"],
	data() {
		return {
			loading: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style scoped></style>
