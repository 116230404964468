import request from "@/utils/request"

const moduleName = "/project"
export default {
	getTeamUsers: data =>
		request(`${moduleName}/${data.projectId}/team-users`, {
			method: "get",
			params: data
		}),
	addTeamUsers: data =>
		request(`${moduleName}/${data.projectId}/team-users`, {
			method: "post",
			body: data
		}),
	removeTeamUsers: data =>
		request(`${moduleName}/${data.projectId}/team-users`, {
			method: "put",
			body: data
		}),
	getUserInfo: data =>
		request(`/auth/user/${data.id}`, {
			method: "get"
		}),
	getUserAndOthers: data =>
		request("/auth/users/assign-role-users-and-others", {
			method: "get",
			params: data
		}),
	getAuthRecordList: data =>
		request(`/project/${data.projectId}/auth-record/query`, {
			method: "get",
			params: data
		}),
	// 获取项目下用户列表
	getProUsers: data =>
		request(`/project/${data.projectId}/team-users/full-query/users`, {
			method: "get",
			params: data
		})
}
