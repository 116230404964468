<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="时间" prop="payTime">
						<DatePicker
							type="datetime"
							placeholder="请选择时间"
							v-model="formData.payTime"
							format="yyyy-MM-dd HH:mm:ss"
							style="width: 100%"
							:clearable="false"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="费用名称" prop="name">
						<Select v-model="formData.name" placeholder="请选择费用名称">
							<Option
								:value="list.id"
								v-for="list in financeNameList"
								:key="list.id"
								:label="list.name"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="费用金额（元）" prop="amount">
						<Input v-model="formData.amount" placeholder="请输入费用金额" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="支付类型" prop="payType">
						<Select
							:transfer="true"
							v-model="formData.payType"
							placeholder="请选择支付类型"
						>
							<Option value="1">收入</Option>
							<Option value="-1">支出</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注" prop="remark">
						<Input
							v-model="formData.remark"
							type="textarea"
							placeholder="请输入备注"
						/>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/financeInfo"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "id", "financeNameList"],
	mixins: [ExtraMixin],
	data() {
		return {
			loading: false,
			formData: {
				payTime: new Date()
			},
			title: "",
			rules: {
				payTime: {
					required: true,
					type: "date",
					message: "时间不能为空",
					trigger: "blur"
				},
				name: {
					required: true,
					message: "费用名称不能为空",
					trigger: "change blur"
				},
				payType: {
					required: true,
					message: "支付类型不能为空",
					trigger: "change blur"
				},
				amount: [
					{ required: true, message: "金额不能为空", trigger: "blur" },
					{
						...this.$rules.money,
						message: "金额必须为正数且不超过2位小数",
						trigger: "blur"
					}
				]
			},
			extraList: []
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					payTime: new Date()
				}
				this.title = "新增财务记录"
				if (this.id) {
					this.title = "修改财务记录"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							projectId: this.$route.params.projectId,
							id: this.id
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								...JSON.parse(res.data.extraData)
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await apiUpdate({
								...this.formData,
								id: this.id,
								amount: window.parseFloat(this.formData.amount),
								projectId: this.$route.params.projectId,
								extraData: this.getExtraData()
							})
						} else {
							res = await apiAdd({
								...this.formData,
								amount: window.parseFloat(this.formData.amount),
								projectId: this.$route.params.projectId,
								extraData: this.getExtraData()
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	},
	created() {
		this.getExtraList("FINANCE")
	},
	components: {
		ExtraForm
	}
}
</script>

<style scoped></style>
