<template>
  <div class="content-wrap">
    <Spin
      fix
      v-if="loading"
    ></Spin>
    <div
      v-for="(list,index) in listdata"
      :key="index"
    >
      <div class="role-title">
        {{list.roleName}}({{list.userList.length}})
        <Button
          type="primary"
          size="small"
          @click="handleAddShow(list)"
          v-if="!(projectActionPermissions.indexOf('btn_project_team_add')===-1)"
        ><Icon type="md-add" />添加</Button>
      </div>
      <div class="user-list">
        <div
          v-for="(user,idx) in list.userList"
          :key="idx"
          class="user-item item"
        >
          <img
            :src="defaultAvatar"
            alt=""
            @click="handleViewShow(user)"
          >
          <div class="user-info">
            <p :title="user.name"  @click="handleViewShow(user)">{{user.name}}</p>
            <div>
              <el-link type="primary" @click="handleViewShow(user)" style="margin-right: 10px;">查看</el-link>
              <Poptip
                confirm
                placement="top"
                title="确定删除该成员吗？"
                :transfer="true"
                @on-ok="deleteHandle(user,list.roleId)"
                v-if="!(projectActionPermissions.indexOf('btn_project_team_add')===-1)"
              >
                <el-link type="danger">删除</el-link>
              </Poptip>

            </div>
          </div>
        </div>
<!--        <div-->
<!--          class="item add-user"-->
<!--          @click="handleAddShow(list)"-->
<!--          v-if="!(projectActionPermissions.indexOf('btn_project_team_add')===-1)"-->
<!--        >-->
<!--          <div class="add">-->
<!--            <p>-->
<!--              <Icon type="md-add" />-->
<!--            </p>-->
<!--            <p>-->
<!--              添加成员-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->

      </div>

    </div>
    <Add
      :visible="addVisible"
      :role="role"
      @onCancel="addVisible = false"
      @onOk="handleAddOk"
    ></Add>
    <ViewDetail
      :visible="viewVisible"
      @onCancel="viewVisible = false"
      :id="viewId"
    ></ViewDetail>
  </div>
</template>

<script>
import api from '@/api/project/team';
import { mapState } from 'vuex';
import Add from './Add.vue';
import ViewDetail from '@/views/Setting/Staff/ViewDetail.vue';
import defaultAvatar from '@/assets/common/teamUser.png';

const { getTeamUsers, removeTeamUsers } = api;
export default {
  name: 'Team',
  data() {
    return {
      defaultAvatar,
      loading: false,
      projectId: this.$route.params.projectId,
      listdata: [],
      addVisible: false,
      role: null,
      viewVisible: false,
      viewId: '',
    };
  },
  methods: {
    initList() {
      this.$asyncDo(async () => {
        this.loading = true;
        const res = await getTeamUsers({
          projectId: this.projectId,
        });
        if (res) {
          this.listdata = res.data;
        }
        this.loading = false;
      });
    },
    // 新增成功刷新列表
    handleAddOk() {
      this.addVisible = false;
      this.initList();
      // 更新待审批流程数量(修改团队成员，角色发生改变，需要更新待办)
      this.$store.dispatch('process/getProjectToDoNum', this.$route.params.projectId);
    },
    // 新增显示
    handleAddShow(obj) {
      this.role = obj;
      this.addVisible = true;
    },
    handleViewShow(obj) {
      this.viewVisible = true;
      this.viewId = obj.id;
    },
    deleteHandle(obj, roleVal) {
      this.$asyncDo(async () => {
        const res = await removeTeamUsers(
          { projectId: this.projectId, userId: obj.id, roleId: roleVal },
        );
        if (res) {
          this.$Message.success('删除成功');
          this.initList();
        }
      });
    },
    cancel() {

    },
  },
  created() {
    this.initList();
  },
  computed: {
    ...mapState('permission', ['projectActionPermissions']),
  },
  components: {
    Add,
    ViewDetail,
  },
};
</script>

<style scoped lang="less">
.role-title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
}
.user-list {
  display: flex;
  flex-wrap: wrap;
}
.item {
  margin-right: 10px;
  margin-bottom: 10px;
  border: solid 1px #ccc;
  display: flex;
  flex-direction: row;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}
.item.add-user {
  width: 78px;
  padding: 0;
  .ivu-icon-md-add {
    font-size: 28px;
  }
  .add {
    width: 78px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.user-item {
  img {
    width: 24px;
  }
  .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    p {
      width: 88px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    & > div {
      button {
        margin-left: 5px;
      }
    }
  }
}
</style>
