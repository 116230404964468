<template>
	<Modal
		:title="'上传附件'"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<!--:accept="'image/jpe,image/jpg,image/jpeg,image/png,image/gif,image/tiff,application/pdf'"-->
		<!--:format="['jpg','pjpeg','jpeg','jfif','pjp','png','gif','tiff','pdf']"-->
		<CtmsUpload
			ref="upAttach"
			:headers="{ token: $store.state.user.token }"
			:action="`${$baseUrl}/project/${projectId}/finance/${uploadId}/files`"
			accept="*"
			:uploadList="defaultList"
			:defaultFileList="defaultList"
			@removeFile="removeFile"
			@updateList="getFileList"
		>
		</CtmsUpload>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>
<script>
import api from "@/api/project/financeInfo"

const { apiGetInvoice, apiDeleteInvoice } = api
export default {
	props: ["visible", "uploadId"],
	data() {
		return {
			defaultList: []
		}
	},
	computed: {
		projectId() {
			return this.$route.params.projectId
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		removeFile(obj) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDeleteInvoice({
					projectId: this.projectId,
					financeId: this.uploadId,
					fileId: obj.id
				})
				if (res) {
					this.$Message.success("删除成功!")
					this.getFileList()
				}
				this.loading = false
			})
		},
		getFileList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetInvoice({
					projectId: this.projectId,
					financeId: this.uploadId
				})
				if (res) {
					this.defaultList = res.data
				}
				this.loading = false
			})
		}
	},
	mounted() {},
	watch: {
		visible() {
			if (this.visible) {
				this.getFileList()
			}
		}
	}
}
</script>
